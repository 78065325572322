<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-card
          border-variant="success"
        >
          <span>TopUp saldo : 1.000.000.000</span>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card
          border-variant="danger"
        >
          <span>Saldo terpotong : 23.000.000</span>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-card
          border-variant="warning"
        >
          <span>Lainnya : 0</span>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4>History pembayaran mitra</h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="text-right"
        >
          <b-button
            variant="success"
            size="sm"
            @click="downloadExcel()"
          >
            <feather-icon
              v-if="!loading"
              icon="DownloadIcon"
              size="16"
              class="text-center"
            />
            <b-spinner
              v-else
              small
              variant="success"
            />
          </b-button>
        </b-col>
      </b-row>
      <br>

      <div>
        <b-row>
          <b-col
            v-for="pay in paymentData"
            :key="pay.time"
            cols="12"
            md="6"
          >
            <b-card
              border-variant="primary"
              class="shadow-p-3"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-badge
                    :variant="pay.kategori == 'masuk' ? 'success' : 'danger'"
                    pill
                  >
                    {{ pay.kategori == 'masuk' ? 'TopUp saldo' : 'Saldo terpotong' }}
                  </b-badge>
                  <h6 class="mt-1">
                    {{ pay.waktu.toDate().toLocaleString() }}
                  </h6>
                  <small>{{ pay.metode }}</small>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="text-right"
                >
                  <p
                    class="font-weight-bold mb-0"
                    :class="pay.status == false ? 'text-danger' : 'text-dark'"
                  >
                    Rp. {{ pay.jumlah }}
                  </p>
                  <small>status : {{ pay.status == true ? 'Berhasil' : 'Belum berhasil' }}</small>
                  <br>
                  <b-button
                    v-if="pay.jenis == 'pemesanan'"
                    variant="outline-success"
                    squared
                    size="sm"
                    class="mt-1"
                    @click="getOrder(pay.nomor)"
                  >
                    <span v-if="!loading">Detail</span>
                    <b-spinner
                      v-else
                      small
                      variant="success"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BBadge, BCol, BRow,
  BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import fileDownload from 'js-file-download'
import { db } from '@/firebase'

export default {
  components: {
    BCard,
    BBadge,
    BCol,
    BRow,
    BButton,
    BSpinner,
  },
  props: ['id'],
  data() {
    return {
      collapsed: false,
      paymentData: [],
      orderData: '',
      loading: false,
    }
  },
  created() {
    this.getPayment()
  },
  methods: {
    /* eslint-disable */
    getPayment() {
      db.collection('mitra').doc(this.id).collection('payment_log').orderBy('waktu', 'desc')
        .get()
        .then(snapshot => {
          const arr = []

          snapshot.docs.forEach(doc => {
            arr.push({
              deskripsi: doc.data().deskripsi,
              kategori: doc.data().kategori,
              metode: doc.data().metode,
              status: doc.data().status,
              waktu: doc.data().waktu,
              jumlah: doc.data().jumlah,
              nomor: doc.data().nomor,
              jenis: doc.data().jenis,
            })
          })
          this.paymentData = [...new Set(arr)]
        })
    },
    getOrder(nomor) {
      this.loading = true
      db.collection('orders').orderBy('waktu', 'asc').startAt('2021-09-16 00:05:07.319011').get()
        .then(snapshot => {
          const arr = []

          snapshot.docs.forEach(doc => {
            if (doc.data().vertikal != null) {
              arr.push({
                id: doc.id,
              })
            } else {
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Data tidak ditemukan',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            }
          })
          this.orderData = [...new Set(arr)]
          if (this.orderData !== null) {
            this.$router.push({ name: 'DetailPesanan', params: { id: nomor } })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'data tidak ditemukan',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    async downloadExcel() {
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/downloadPaymentLog',
        method: 'POST',
        data: {
          no_hp: this.id,
        },
      }
      await this.$axios(options).then(res => {
        fileDownload(res.data, `History transaksi ${this.id}.csv`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data berhasil di download',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style>

</style>
